import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TgClient } from '../../../models/telegram/tg-client.model';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent {

  @Input()
  clients: TgClient[] | null = [];

  @Output()
  editClicked = new EventEmitter<TgClient>();

  @Output()
  removeClicked = new EventEmitter<TgClient>();  
}

import { Component, Input } from '@angular/core';
import { TgMessage } from '../models/telegram/tg-message.model';
import { TgScreenshot } from '../models/telegram/tg-screenshot.model';
import { ScreenshotService } from '../services/screenshot.service';

@Component({
  selector: 'app-edit-tg-screenshot',
  templateUrl: './edit-tg-screenshot.component.html',
  styleUrls: ['./edit-tg-screenshot.component.css']
})
export class EditTgScreenshotComponent {
  constructor(private screenshotService: ScreenshotService) { }

  @Input()
  screenshot!: TgScreenshot;

  showAviatorModal: boolean = false;

  showLuckyJetModal: boolean = false;

  selectedMessage!: TgMessage;

  jetModel: any = {
    balance: 999.91,
    currency: '₽',
    coef: 1.8,
    bid1: 740,
    coef1: '2.00',
    bid2: 680,
    coef2: '2.00',
    coefHistory1: 1,
    coefHistory2: 2,
    coefHistory3: 3,
    coefHistory4: 4,
    coefHistory5: 5,
    coefHistory6: 6,
    status: 'flying',
    pendingWidth: 45,
    win1: false,
    win1coef: 1.5,
    win1amount: 100,
    win2: false,
    win2coef: 2,
    win2amount: 200,
    scrollTop: 0,
    height: 844
  }

  aviatorModel: any = {
    balance: 999.91,
    currency: 'USD',
    coef: 1.8,
    bid1: 740,
    coef1: '2.00',
    bid2: 680,
    coef2: '2.00',
    coefHistory1: 1,
    coefHistory2: 2,
    coefHistory3: 3,
    coefHistory4: 4,
    coefHistory5: 5,
    coefHistory6: 6,
    coefHistory7: 7,
    coefHistory8: 8,
    coefHistory9: 9,
    coefHistory10: 10,
    status: 'flying',
    pendingWidth: 45,
    win1: true,
    win1coef: 1.5,
    win1amount: 100,
    win2: true,
    win2coef: 2,
    win2amount: 200,
    scrollTop: 0,
    height: 844,
    bets: [
      { time: '19:30', bid: 100, coef: 1.2, success: true },
      { time: '19:30', bid: 50, coef: 1.9, success: false }
    ]
  }

  isLoading: boolean = false;

  openLuckyJet(message: TgMessage) {
    this.showLuckyJetModal = true;
    this.selectedMessage = message;
  }

  openAviator(message: TgMessage) {
    this.showAviatorModal = true;
    this.selectedMessage = message;
  }

  saveJet() {
    this.isLoading = true;
    this.screenshotService
      .save(this.jetModel, 'jet-screenshot')
      .subscribe(data => {
        this.selectedMessage.images.push(data.url);
        this.isLoading = false;
        this.showLuckyJetModal = false;
      });
  }

  saveAviator() {
    this.isLoading = true;
    this.screenshotService
      .save(this.aviatorModel, 'aviator-screenshot')
      .subscribe(data => {
        this.selectedMessage.images.push(data.url);
        this.isLoading = false;
        this.showAviatorModal = false;
      });
  }
}

export class TgHero {
  constructor(
    public id: string,
    public title: string,
    public names: string[],
    public wins: string[],
    public events: string[],
    public backgroundUrl: string
  ) { }
}

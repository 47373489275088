import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, map, tap } from 'rxjs';
import { TgHero } from '../../../models/telegram/tg-hero.model';
import { HeroService } from '../../../services/hero.service';

@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html',
  styleUrls: ['./heroes.component.css']
})
export class HeroesComponent {
  constructor(
    private heroesService: HeroService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.loadHeroes();
    this.heroForm = this.fb.group({
      id: [],
      title: ['', Validators.required],
      names: this.fb.array(['', Validators.required], this.atLeastOne),
      wins: this.fb.array(['', Validators.required], this.atLeastOne),
      events: this.fb.array(['', Validators.required], this.atLeastOne),
      backgroundUrl: ['', Validators.required]
    });
  }

  get names() {
    return this.heroForm.controls["names"] as FormArray;
  }

  addName(name: string) {
    this.names.push(this.fb.control(name, Validators.required));
  }

  removeName(index: number) {
    this.names.removeAt(index);
  }

  get wins() {
    return this.heroForm.controls["wins"] as FormArray;
  }

  addWin(win: string) {
    this.wins.push(this.fb.control(win, Validators.required));
  }

  removeWin(index: number) {
    this.wins.removeAt(index);
  }

  get events() {
    return this.heroForm.controls["events"] as FormArray;
  }

  addEvent(event: string) {
    this.events.push(this.fb.control(event, Validators.required));
  }

  removeEvent(index: number) {
    this.events.removeAt(index);
  }

  get backgroundUrl() {
    return this.heroForm.controls["backgroundUrl"];
  }

  get id() {
    return this.heroForm.controls["id"];
  }

  get title() {
    return this.heroForm.controls["title"];
  }

  atLeastOne(control: AbstractControl): ValidationErrors | null {

    const formArray = control as FormArray;
    if (!formArray)
      return null;

    const invalid = formArray.controls.length < 1;
    return invalid ? { 'atLeastOneError': true } : null;
  }

  heroForm!: FormGroup;

  heroes$!: Observable<TgHero[]>;

  showModal: boolean = false;

  selectedHero!: TgHero;

  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;

  openModal(hero?: TgHero) {
    this.heroForm.reset();

    this.names.clear();
    this.wins.clear();
    this.events.clear();

    if (hero) {
      hero.names.forEach(name => this.addName(name));
      hero.wins.forEach(win => this.addWin(win));
      hero.events.forEach(event => this.addEvent(event));
      this.heroForm.patchValue({ id: hero.id });
      this.heroForm.patchValue({ title: hero.title });
      this.heroForm.patchValue({ backgroundUrl: hero.backgroundUrl });
    } else {
      this.addName('');
      this.addWin('');
      this.addEvent('');
    }

    this.showModal = true;
  }

  saveHero() {
    if (!this.heroForm.valid) {
      this.heroForm.markAllAsTouched();
      return;
    }      

    const callback = () => {
      this.showModal = false;
      this.loadHeroes();
    }

    if (this.id.value) {
      this.heroesService.update(this.heroForm.value).subscribe(callback);
    }
    else {
      this.heroesService.create(this.heroForm.value).subscribe(callback);
    }
  }

  removeHero(hero: TgHero) {
    const result = window.confirm('Удалить?');
    if (result) {
      this.heroesService.delete(hero.id).subscribe(() => this.loadHeroes());
    }
  }

  loadHeroes() {
    this.heroes$ = this.heroesService
      .get(this.currentPage, this.itemsPerPage)
      .pipe(
        tap(response => this.totalItems = response.totalCount),
        map(response => response.items)
      );
  }

  onPageChanged(page: number) {
    this.currentPage = page;
    this.loadHeroes();
  }
}

<div class="container">

  <div class="field is-grouped">

    <div class="control has-icons-left">
      <input class="input"
             type="text"
             placeholder="Поиск по имени"
             (input)="onSearch($event)" />
      <span class="icon is-left">
        <i class="fas fa-search"></i>
      </span>
    </div>

    <div class="field is-grouped ml-auto">
      <div class="control">
        <input class="input"
               type="text"
               placeholder="Имя нового таймлайна"
               [(ngModel)]="newTimelineName" />
      </div>
      <div class="control">
        <button class="button is-success" (click)="addTimeline()">Добавить таймлайн</button>
      </div>
    </div>

  </div>

  <!--<cdk-virtual-scroll-viewport itemSize="50" class="timeline-list" (scrolledIndexChange)="onScroll()">
    <div *cdkVirtualFor="let timeline of timelines$ | async" class="box">
      <p>{{ timeline.name }}</p>
    </div>
  </cdk-virtual-scroll-viewport>-->

  <div class="columns">
    <div class="column">
      <table class="table" style="width: 100%">
        <thead>
          <tr>
            <th>Имя</th>
            <th>Кол-во сессий</th>
            <th>Изменен</th>
            <th>Создан</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let timeline of timelines$ | async">
            <td>
              <a (click)="viewTimeline(timeline.id)">{{ timeline.name }}</a>
            </td>
            <td>
              {{timeline.numberOfSessions}}
            </td>
            <td>
              {{ timeline.updatedAt | date: 'dd/MM/yyyy' }}
            </td>
            <td>
              {{ timeline.createdAt | date: 'dd/MM/yyyy' }}
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="isLoading" class="has-text-centered">
        <button class="button is-loading">Загрузка...</button>
      </div>
    </div>

    <div class="column is-one-third" *ngIf="selectedTimeline">
      <h3 class="title is-4">
        {{selectedTimeline.name}} ({{selectedTimeline.sessions?.length}})
      </h3>

      <p class="mb-3" *ngFor="let session of selectedTimeline.sessions">
        <a [routerLink]="'/sessions/edit/' + session.id">
          {{session.name}}
        </a>
      </p>

    </div>
  </div>

</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TgClient } from '../models/telegram/tg-client.model';
import { PagedResult } from '../models/screen.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL')
    private baseUrl: string
  ) { }

  get(pageNumber: number, pageSize: number): Observable<PagedResult<TgClient>> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize);

    return this.http.get<PagedResult<TgClient>>(`${this.baseUrl}api/clients`, {params});
  }

  getBy(gender: string, region: string, lang: string): Observable<PagedResult<TgClient>> {
    const params = new HttpParams()
      .set('pageNumber', 1)
      .set('pageSize', 5000)
      .set('gender', gender)
      .set('region', region)
      .set('lang', lang);

    return this.http.get<PagedResult<TgClient>>(`${this.baseUrl}api/clients`, { params });
  }

  create(newClient: TgClient): Observable<TgClient> {
    return this.http.post<TgClient>(`${this.baseUrl}api/clients`, newClient);
  }

  update(updatedClient: TgClient): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}api/clients/${updatedClient.id}`, updatedClient);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}api/clients/${id}`);
  }
}

<table class="table mb-5">
  <tr *ngFor="let hero of heroes">
    <td>
      {{hero.title || hero.names[0]}}
    </td>
    <td>
      <div class="buttons">
        <button class="button is-link" (click)="editClicked.emit(hero)">Редактировать</button>
        <button class="button is-danger" (click)="removeClicked.emit(hero)">Удалить</button>
      </div>
    </td>
  </tr>
</table>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { TgClient } from '../models/telegram/tg-client.model';
import { TgHero } from '../models/telegram/tg-hero.model';
import { TgScreenshot } from '../models/telegram/tg-screenshot.model';
import { TgSettings } from '../models/telegram/tg-settings.model';
import { ClientService } from '../services/client.service';
import { HeroService } from '../services/hero.service';

@Component({
  selector: 'app-tg-settings',
  templateUrl: './tg-settings.component.html',
  styleUrls: ['./tg-settings.component.css']
})
export class TgSettingsComponent {
  constructor(
    private clientService: ClientService,
    private heroService: HeroService,
  ) { }

  ngOnInit() {

    this.loadClients();

    this.heroes$ =
      this.heroService.get(1, 5000)
        .pipe(
          map(response => response.items),
          tap(heroes => {
            if (!this.settings.hero.id && heroes[0]) {
              this.settings.hero = heroes[0];
            }
          })
        );
  }

  @Output()
  heroChanged = new EventEmitter<TgHero>();

  @Input()
  settings!: TgSettings;

  @Input()
  screenshot!: TgScreenshot;

  @Input()
  hideClient: boolean = false;

  @Input()
  hideHero: boolean = false;

  clients$!: Observable<TgClient[]>;

  heroes$!: Observable<TgHero[]>;

  public onHeroChange(hero: TgHero) {
    this.heroChanged.emit(hero);
  }

  public compareById(o1: any, o2: any) {
    return o1.id === o2.id;
  }

  public updateTime(timeString: string): void {
    this.updateTimeProp(timeString, this.settings.time);
  }

  public updateMessagingStartTime(timeString: string): void {
    this.updateTimeProp(timeString, this.settings.messagingStartTime);
    TgScreenshot.setMessagesTime(this.screenshot);
  }

  private updateTimeProp(timeString: string, prop: Date): void {
    const [hours, minutes] = timeString.split(':');
    prop.setHours(parseInt(hours, 10), parseInt(minutes, 10));
  }

  setMessagesTime(): void {
    TgScreenshot.setMessagesTime(this.screenshot);
  }

  loadClients() {
    this.clients$ =
      this.clientService.getBy(this.settings.clientGender, this.settings.clientRegion, this.settings.clientLang)
        .pipe(
          map(response => response.items),
          tap(clients => {
            if (!this.settings.client.id && clients[0]) {
              this.settings.client = clients[0];
            }
          })
        );
  }

  onClientGenderChange(value: string) {
    this.settings.client.id = "";
    this.settings.clientGender = value;
    this.loadClients();
  }

  onClientRegionChange(value: string) {
    this.settings.client.id = "";
    this.settings.clientRegion = value;
    this.loadClients();
  }

  onClientLangChange(value: string) {
    this.settings.client.id = "";
    this.settings.clientLang = value;
    this.loadClients();
  }
}

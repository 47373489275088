<div class="form">

  <div class="field is-grouped">

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Пол клиента</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select [ngModel]="settings.clientGender" (ngModelChange)="onClientGenderChange($event)">
              <option value="">Все</option>
              <option value="male">Мужской</option>
              <option value="female">Женский</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Регион</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select [ngModel]="settings.clientRegion" (ngModelChange)="onClientRegionChange($event)">
              <option value="">Все</option>
              <option value="slavic">Славяне</option>
              <option value="kaz">Казахстан</option>
              <option value="uzb">Узбекистан</option>
              <option value="kaz-eth">Казахстан этн.</option>
              <option value="uzb-eth">Узбекистан этн.</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Язык</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select [ngModel]="settings.clientLang" (ngModelChange)="onClientLangChange($event)">
              <option value="">Все</option>
              <option value="rus">Русский</option>
              <option value="eng">Английский</option>
              <option value="kaz">Казахский</option>
              <option value="uzb">Узбекский</option>
            </select>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="field" [hidden]="hideClient">
    <label class="label">Клиент</label>
    <div class="control">
      <div class="select is-fullwidth">
        <select [(ngModel)]="settings.client" [compareWith]="compareById" class="select">
          <ng-container *ngIf="(clients$ | async) as clients">
            <option *ngIf="clients.length === 0" disabled selected>Нет совпадений</option>
            <option *ngFor="let client of clients" [ngValue]="client" [selected]="settings.client.id == client.id">
              {{client.name}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>

  <div class="field" *ngIf="!hideHero">
    <label class="label">Герой</label>
    <div class="control">
      <div class="select is-fullwidth">
        <select [(ngModel)]="settings.hero" [compareWith]="compareById" (ngModelChange)="onHeroChange($event)" class="select" style="border-color: hsl(141, 71%, 48%)">
          <option *ngFor="let hero of heroes$ | async" [ngValue]="hero" [selected]="settings.hero.id == hero.id">
            {{hero.title || hero.names[0]}}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="field is-grouped">

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Время</label>
        <div class="control">
          <input type="time" class="input" [ngModel]="settings.time | date:'HH:mm'" (ngModelChange)="updateTime($event)" />
        </div>
      </div>
    </div>

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Онлайн</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select [(ngModel)]="settings.onlineStatus">
              <option value="online">в сети</option>
              <option value="recently">был(а) недавно</option>
              <option value="recently_time">был(a) .. минут назад</option>
              <option value="custom">свой статус</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="control is-expanded" *ngIf="settings.onlineStatus==='custom'">
      <div class="field">
        <label class="label">Свой статус</label>
        <div class="control">
          <input type="text" [(ngModel)]="settings.customOnlineStatus" class="input" />
        </div>
      </div>
    </div>

    <div class="control is-expanded" *ngIf="settings.onlineStatus==='recently_time'">
      <div class="field">
        <label class="label">Минуты</label>
        <div class="control">
          <input type="number" [(ngModel)]="settings.onlineStatusMinutes" class="input" />
        </div>
      </div>
    </div>

  </div>

  <div class="field is-grouped">

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Батарея</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select [(ngModel)]="settings.battery">
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Сеть</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select [(ngModel)]="settings.network">
              <option value="1">Low</option>
              <option value="2">Medium</option>
              <option value="3">High</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Wi-Fi</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select [(ngModel)]="settings.wiFi">
              <option value="1">Low</option>
              <option value="2">Medium</option>
              <option value="3">High</option>
              <option value="4">LTE</option>
              <option value="5">5G</option>
            </select>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="field">
    <label class="label">Непрочитанное</label>
    <div class="control">
      <input type="number" [(ngModel)]="settings.unreadMessages" class="input" />
    </div>
  </div>

  <div class="field is-grouped" *ngIf="settings.client.avatar">
    <div class="control is-expanded">
      <div class="field">
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="settings.showOnlyInitials">
          Показать инициалы
        </label>
      </div>
    </div>
    <div class="control is-expanded" *ngIf="settings.showOnlyInitials">
      <div class="field">
        <div class="control">
          <div class="select">
            <select [(ngModel)]="settings.initialsBgNumber" [class]="'avatar-bg-' + settings.initialsBgNumber">
              <option class="avatar-bg-1" value="1">Оранжевый</option>
              <option class="avatar-bg-2" value="2">Лосось</option>
              <option class="avatar-bg-3" value="3">Лаванда</option>
              <option class="avatar-bg-4" value="4">Бирюзовый</option>
              <option class="avatar-bg-5" value="5">Сальвия</option>
              <option class="avatar-bg-6" value="6">Фиолетовый</option>
              <option class="avatar-bg-7" value="7">Голубой</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="field">
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="settings.showAddOrBan">
      Показать "Добавить/Заблокировать"
    </label>
  </div>

  <div class="field">
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="settings.showKeyboard">
      Клавиатура
    </label>
  </div>

  <div class="field is-grouped">
    <div class="control is-expanded">
      <div class="field">
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="settings.showScrollDown">
          Скролл
        </label>
      </div>
    </div>
    <div class="control is-expanded" *ngIf="settings.showScrollDown">
      <div class="field">
        <div class="control">
          <input type="number" [(ngModel)]="settings.scrollCount" class="input" placeholder="Кол-во на скролле" />
        </div>
      </div>
    </div>
  </div>

  <div class="field is-grouped">

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Время первого сообщения</label>
        <div class="control">
          <input type="time" class="input" [ngModel]="settings.messagingStartTime | date:'HH:mm'" (ngModelChange)="updateMessagingStartTime($event)" />
        </div>
      </div>
    </div>

    <div class="control is-expanded">
      <div class="field">
        <label class="label">Макс. интервал между сообщениями (мин)</label>
        <div class="control">
          <input type="number" class="input" [(ngModel)]="settings.maxInvervalBtwMessages" (change)="setMessagesTime()" />
        </div>
      </div>
    </div>

  </div>
</div>

<table class="table mb-5">
  <tr *ngFor="let client of clients">
    <td>
      <figure class="image is-square is-32x32">
        <img class="is-rounded" [src]="client.avatar">
      </figure>
    </td>
    <td>
      {{client.name}}
    </td>
    <td>
      <div class="buttons">
        <button class="button is-link" (click)="editClicked.emit(client)">Редактировать</button>
        <button class="button is-danger" (click)="removeClicked.emit(client)">Удалить</button>
      </div>
    </td>
  </tr>
</table>
